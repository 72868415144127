import { Amplify, API } from 'aws-amplify';

import {
  contractsByCityAndDate,
  contractsByRegionAndDate,
  contractsBySubRegionAndDate,
} from '../graphql/custom-queries';

export const fetchContracts = async (type, id, startDate, endDate) => {
  Amplify.configure({
    aws_appsync_region: 'eu-central-1',
    aws_appsync_graphqlEndpoint:
      'https://zhipavkohzdznpiswkdenqtpom.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-njtbtv2uijfjlkdix42ccpjoia',
  });

  const data = [];
  let token = null;
  const query = getQuery(type);

  do {
    const response = await API.graphql({
      query: query.type,
      variables: {
        id: id,
        date: {
          between: [startDate, endDate],
        },
        nextToken: token,
      },
      authMode: 'API_KEY',
    });
    data.push(...response.data[query.name].items);
    token = response.data[query.name].nextToken;
  } while (token);

  return data.map((d) => {
    d.n = JSON.parse(d.n);
    return d;
  });
};

const getQuery = (type) => {
  switch (type) {
    case 'region':
      return { type: contractsByRegionAndDate, name: 'contractsByOpstinaIDAndDatumU' };
    case 'subRegion':
      return { type: contractsBySubRegionAndDate, name: 'contractsByKoIDAndDatumU' };
    default:
      return { type: contractsByCityAndDate, name: 'contractsByGradIDAndDatumU' };
  }
};
