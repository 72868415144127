import React, { memo } from 'react';
import ReactGA4 from 'react-ga4';

import './HeaderTertiary.scss';

const HeaderTertiary = () => {
  const analyticsEvent = () => {
    ReactGA4.event({
      category: 'Ad',
      action: 'Magnat',
    });
  };

  return (
    <div className='header-tertiary'>
      <a
        href='https://nekretninemagnat95.godaddysites.com/kupujemo'
        target='_blank'
        rel='noopener noreferrer'
        onClick={analyticsEvent}
      >
        <span>Kupujemo Dobar stan !&nbsp;</span>
        <span>MAGNAT&nbsp;</span>
        <span>0645.800.900</span>
      </a>
    </div>
  );
};

export default memo(HeaderTertiary, () => true);
