import { cities, months, quarters, years } from './filterConstants';
import { fetchContracts } from '../api/api';
import { calculateStatisticsData } from './helpers';

const isHouseFlat = (subjects) => {
  const isHF = subjects.some((s) => {
    return s.vNepID === 831 && s.pvNepNaziv !== 'Стан у стамбеној  згради за колективно становање';
  });
  return isHF;
};
const filterGarage = (subjects, garage) => {
  const hasG = subjects.some((s) => {
    return s.vNepID !== 831;
  });
  return garage === 1 ? hasG : !hasG;
};

export const getAllContracts = async (month, year, region, subRegion, lastMonth = null) => {
  const startDate = `${year - 1}-${month}-01`;
  const endDate = lastMonth ? `${year}-${lastMonth}-31` : `${year}-${month}-31`;
  let contracts = [];

  if (subRegion !== '-1') {
    contracts = await fetchContracts('subRegion', subRegion, startDate, endDate);
  } else if (region !== '1' && region !== '3') {
    contracts = await fetchContracts('region', region, startDate, endDate);
  } else {
    let id = Number(region);
    const response = await Promise.all(
      cities
        .find((c) => c.value === id)
        .regions.map((r) => fetchContracts('region', r, startDate, endDate)),
    );
    contracts = response.flat();
  }

  return contracts;
};

export const getStatisticsData = (contracts, month, year, garage = 0, houseFlat = true) => {
  const data = [];
  let m = year > years[0].value ? month : months[0].value;
  let y = year > years[0].value ? year - 1 : year;
  while (y < year || (y === year && m <= month)) {
    // eslint-disable-next-line
    const monthString = months.find(({ value }) => value === m).stringValue;
    // eslint-disable-next-line
    const monthlyContracts = contracts.filter(
      (c) =>
        monthString === c.datumU.split('-')[1] &&
        y === parseInt(c.datumU.split('-')[0]) &&
        (houseFlat || !isHouseFlat(c.n)) &&
        (garage === 0 || filterGarage(c.n, garage)),
    );

    const currentData = calculateStatisticsData(
      // eslint-disable-next-line
      months.find(({ value }) => value === m),
      years.find(({ value }) => value === y),
      monthlyContracts,
    );
    data.push(currentData);
    if (m < 12) {
      m++;
    } else {
      m = 1;
      y++;
    }
  }

  return data;
};

export const getQuarterlyStatisticsData = (
  contracts,
  quarter,
  year,
  garage = 0,
  houseFlat = true,
) => {
  const month = quarter * 3 - 2;
  const data = [];
  let m = year > years[0].value ? month : months[0].value;
  let y = year > years[0].value ? year - 1 : year;
  while (y < year || (y === year && m <= month)) {
    // eslint-disable-next-line
    const monthStrings = [
      months.find(({ value }) => value === m).stringValue,
      months.find(({ value }) => value === m + 1).stringValue,
      months.find(({ value }) => value === m + 2).stringValue,
    ];
    // eslint-disable-next-line
    const monthlyContracts = contracts.filter(
      (c) =>
        monthStrings.includes(c.datumU.split('-')[1]) &&
        y === parseInt(c.datumU.split('-')[0]) &&
        (houseFlat || !isHouseFlat(c.n)) &&
        (garage === 0 || filterGarage(c.n, garage)),
    );

    const currentData = calculateStatisticsData(
      // eslint-disable-next-line
      quarters.find(({ firstMonth }) => firstMonth === m),
      years.find(({ value }) => value === y),
      monthlyContracts,
    );
    data.push(currentData);
    if (m < 10) {
      m += 3;
    } else {
      m = 1;
      y++;
    }
  }

  return data;
};
