import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import _ from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import ReactGA4 from 'react-ga4';
import L from 'leaflet';
import {
  Circle,
  FeatureGroup,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { EditControl } from 'react-leaflet-draw';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TableRowsIcon from '@mui/icons-material/TableRows';
import MapIcon from '@mui/icons-material/Map';
import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, FormControlLabel, ThemeProvider, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../../../contexts/ThemeContext';
import {
  calculateDistance,
  formatDate,
  formatEur,
  isPointInPolygon,
} from '../../../helpers/helpers';
import ReportContractsSimple from '../report-contracts/ReportContractsSimple';
import { darkTheme } from '../../../helpers/darkTheme';

import './ReportMap.scss';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/styles.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { themes } from '../../../helpers/constants';

L.drawLocal.draw.toolbar.buttons.polygon = 'Nacrtaj oblast pretrage';
L.drawLocal.draw.handlers.polygon.tooltip = {
  start: 'Kliknite da biste počeli sa crtanjem oblasti pretrage',
  cont: 'Kliknite da biste nastavili sa crtanjem oblasti pretrage',
  end: 'Kliknite na prvu tačku da biste zatvorili oblast pretrage',
};

const setLeafletZoomButtonTitles = () => {
  const zoomInElement = document.getElementsByClassName('leaflet-control-zoom-in')[0];
  zoomInElement?.setAttribute('title', 'Zumiraj');
  const zoomOutElement = document.getElementsByClassName('leaflet-control-zoom-out')[0];
  zoomOutElement?.setAttribute('title', 'Odzumiraj');
};

export default function ReportMap({ contracts }) {
  const { theme } = useContext(ThemeContext);
  const [isMapMobile, setIsMapMobile] = React.useState(true);
  const [type, setType] = React.useState('all');
  const [building, setBuilding] = React.useState('');
  const [showEligibleOnly, setShowEligibleOnly] = React.useState(false);
  const [minSquarePrice, setMinSquarePrice] = React.useState('');
  const [maxSquarePrice, setMaxSquarePrice] = React.useState('');
  const [minPrice, setMinPrice] = React.useState('');
  const [maxPrice, setMaxPrice] = React.useState('');
  const [minArea, setMinArea] = React.useState('');
  const [maxArea, setMaxArea] = React.useState('');
  const [polygons, setPolygons] = React.useState([]);
  const [polygonLayers, setPolygonLayers] = React.useState([]);
  const mapRef = useRef();

  useEffect(() => {
    ReactGA4.send({ hitType: 'pageview', page: '/map', title: 'Map' });
    setLeafletZoomButtonTitles();
  }, []);

  useEffect(() => {
    setBuilding('');
  }, [contracts]);

  const isSecondaryFilterActive = () => {
    return (
      showEligibleOnly ||
      minSquarePrice !== '' ||
      maxSquarePrice !== '' ||
      minPrice !== '' ||
      maxPrice !== '' ||
      minArea !== '' ||
      maxArea !== ''
    );
  };

  const isFilterActive = () => {
    return type !== 'all' || building !== '' || polygons.length || isSecondaryFilterActive();
  };

  const changeBuilding = (value) => {
    ReactGA4.event({
      category: 'Building',
      action: value,
    });
    setBuilding(value);
  };

  const getEligibleContracts = useMemo(() => {
    const eligibleContracts = contracts.filter(
      (c) => !c.subjects.find((s) => calculateDistance(c.lat, c.long, s.lat, s.long) > 40),
    );
    return eligibleContracts;
  }, [contracts]);

  const getFilteredContracts = useMemo(() => {
    let typeFilteredContracts = getEligibleContracts;
    if (type !== 'all') {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.type === type);
    }
    if (building) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.location === building);
    }
    if (showEligibleOnly) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.eligible);
    }
    if (minSquarePrice) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.squarePrice >= minSquarePrice);
    }
    if (maxSquarePrice) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.squarePrice <= maxSquarePrice);
    }
    if (minPrice) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.price >= minPrice);
    }
    if (maxPrice) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.price <= maxPrice);
    }
    if (minArea) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.area >= minArea);
    }
    if (maxArea) {
      typeFilteredContracts = typeFilteredContracts.filter((c) => c.area <= maxArea);
    }
    if (polygons.length) {
      typeFilteredContracts = typeFilteredContracts.filter((c) =>
        polygons.some((p) => isPointInPolygon(c.lat, c.long, p)),
      );
    }
    return typeFilteredContracts;
  }, [
    contracts,
    type,
    building,
    showEligibleOnly,
    minSquarePrice,
    maxSquarePrice,
    minPrice,
    maxPrice,
    minArea,
    maxArea,
    polygons,
  ]);

  const getBuildings = () => {
    const count = _.countBy(contracts.map((c) => c.location).filter((c) => c !== ''));
    return _.map(count, (v, k) => ({ name: k, count: v })).sort((a, b) => b.count - a.count);
  };

  const resetFilters = () => {
    setType('all');
    setBuilding('');
    setShowEligibleOnly(false);
    setMinSquarePrice('');
    setMaxSquarePrice('');
    setMinPrice('');
    setMaxPrice('');
    setMinArea('');
    setMaxArea('');
    deletePolygons();
  };

  const getValidSquarePrice = (squarePrice) => {
    return squarePrice && isFinite(squarePrice) && squarePrice > 0
      ? `${formatEur(squarePrice)}/m²`
      : '-';
  };

  const getValidMapSquarePrice = (squarePrice) => {
    return squarePrice && isFinite(squarePrice) && squarePrice > 0 ? squarePrice : '-';
  };

  const generateIcon = (c) => {
    return L.divIcon({
      className: 'map-icon',
      iconSize: [42, 24],
      iconAnchor: [21, 12],
      popupAnchor: [0, -16],
      html: `<div class='map-icon-content type-${c.type}'>${getValidMapSquarePrice(
        c.squarePrice,
      )}</div>`,
    });
  };

  const MarkerBounds = () => {
    const map = useMap();

    useEffect(() => {
      getFilteredContracts.length &&
        map.fitBounds(
          polygons.length ? polygons : getFilteredContracts.map((c) => [c.lat, c.long]),
        );
    }, [getFilteredContracts]);
  };

  const SubjectLocation = ({ lat, long }) => {
    const map = useMap();

    return (
      <div className='subject-location' onClick={() => map.flyTo([lat, long])}>
        <a>&nbsp;na drugoj lokaciji</a>
      </div>
    );
  };

  const getTypeName = (type) => {
    switch (type) {
      case '1':
        return 'Starogradnja';
      case '2':
        return 'Novogradnja';
      default:
        return 'Ostalo';
    }
  };

  const onShowOnMap = (contract) => {
    setIsMapMobile(true);
    setTimeout(() => {
      mapRef.current.flyTo([contract.row.subjects[0].lat, contract.row.subjects[0].long], 18, {
        duration: 1,
      });
    }, 10);
  };

  const savePolygon = (layer) => {
    setPolygons((p) => [...p, layer._latlngs[0].map((ll) => [ll.lat, ll.lng])]);
    setPolygonLayers((p) => [...p, layer]);
  };

  const deletePolygons = () => {
    polygonLayers.forEach((l) => mapRef.current.removeLayer(l));
    setPolygons([]);
    setPolygonLayers([]);
  };

  const MapFilters = () => {
    const [showAllFilters, setShowAllFilters] = React.useState(isSecondaryFilterActive());

    const debouncedMinSquarePrice = useDebouncedCallback((value) => {
      setMinSquarePrice(value);
    }, 700);

    const debouncedMaxSquarePrice = useDebouncedCallback((value) => {
      setMaxSquarePrice(value);
    }, 700);

    const debouncedMinPrice = useDebouncedCallback((value) => {
      setMinPrice(value);
    }, 700);

    const debouncedMaxPrice = useDebouncedCallback((value) => {
      setMaxPrice(value);
    }, 700);

    const debouncedMinArea = useDebouncedCallback((value) => {
      setMinArea(value);
    }, 700);

    const debouncedMaxArea = useDebouncedCallback((value) => {
      setMaxArea(value);
    }, 700);

    const AdditionalFilters = () => {
      return (
        <Collapse in={showAllFilters} timeout='auto' unmountOnExit>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                checked={!showEligibleOnly}
                onChange={() => setShowEligibleOnly(!showEligibleOnly)}
                sx={{
                  padding: '4px',
                }}
              />
            }
            label='Prikaži ugovore koji ne ulaze u statistiku'
            sx={{
              '&.MuiFormControlLabel-root': {
                marginLeft: 0,
                marginRight: 0,
              },
              '& .MuiFormControlLabel-label': {
                fontSize: 14,
                '@media screen and (max-width: 899px)': {
                  fontSize: 12,
                },
              },
            }}
          />
          <div className='filter-title'>Cena kvadrata (€/m²)</div>
          <form>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label='Od'
              size='small'
              defaultValue={minSquarePrice}
              onChange={(e) => debouncedMinSquarePrice(e.target.value)}
              sx={{
                '& label.Mui-focused': {
                  color: themes[theme].main,
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: themes[theme].main,
                  },
                },
              }}
            />
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label='Do'
              size='small'
              defaultValue={maxSquarePrice}
              onChange={(e) => debouncedMaxSquarePrice(e.target.value)}
              sx={{
                '& label.Mui-focused': {
                  color: themes[theme].main,
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: themes[theme].main,
                  },
                },
              }}
            />
          </form>
          <div className='filter-title'>Cena stana (€)</div>
          <form>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label='Od'
              size='small'
              defaultValue={minPrice}
              onChange={(e) => debouncedMinPrice(e.target.value)}
              sx={{
                '& label.Mui-focused': {
                  color: themes[theme].main,
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: themes[theme].main,
                  },
                },
              }}
            />
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label='Do'
              size='small'
              defaultValue={maxPrice}
              onChange={(e) => debouncedMaxPrice(e.target.value)}
              sx={{
                '& label.Mui-focused': {
                  color: themes[theme].main,
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: themes[theme].main,
                  },
                },
              }}
            />
          </form>
          <div className='filter-title'>Kvadratura (m²)</div>
          <form>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label='Od'
              size='small'
              defaultValue={minArea}
              onChange={(e) => debouncedMinArea(e.target.value)}
              sx={{
                '& label.Mui-focused': {
                  color: themes[theme].main,
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: themes[theme].main,
                  },
                },
              }}
            />
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label='Do'
              size='small'
              defaultValue={maxArea}
              onChange={(e) => debouncedMaxArea(e.target.value)}
              sx={{
                '& label.Mui-focused': {
                  color: themes[theme].main,
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: themes[theme].main,
                  },
                },
              }}
            />
          </form>
        </Collapse>
      );
    };

    return (
      <div className='map-filters'>
        <ThemeProvider theme={darkTheme(theme)}>
          <FormControl
            sx={{
              mx: '6px',
              my: 1,
              minWidth: 140,
              maxWidth: 140,
            }}
            size='small'
          >
            <div className='filter-title'>Tip stana</div>
            <Select
              id='type'
              value={type}
              MenuProps={{
                style: { zIndex: 3000 },
              }}
              onChange={(e) => setType(e.target.value)}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: themes[theme].main,
                },
              }}
            >
              <MenuItem value='all'>Svi</MenuItem>
              <MenuItem value='2'>Novogradnja</MenuItem>
              <MenuItem value='1'>Starogradnja</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              mx: '6px',
              my: 1,
              minWidth: useMediaQuery('(max-width: 499px)') ? 150 : 140,
              maxWidth: useMediaQuery('(max-width: 499px)') ? 150 : 304,
            }}
            size='small'
          >
            <div className='filter-title'>Zgrada</div>
            <Select
              id='building'
              value={building}
              renderValue={(p) => p}
              MenuProps={{
                style: { zIndex: 3000 },
              }}
              onChange={(e) => changeBuilding(e.target.value)}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: themes[theme].main,
                },
              }}
            >
              {getBuildings().map((b) => (
                <MenuItem key={b.name} value={b.name}>
                  {b.name} ({b.count})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className='filters-bottom'>
            <Button
              variant='contained'
              sx={{
                display: useMediaQuery('(max-width: 899px)') ? 'inline-flex' : 'none',
                height: '40px',
                width: '120px',
                padding: '6px 22px',
                fontWeight: 400,
                color: '#fff',
                background: themes[theme].button,
                borderRadius: '20px',
                '&:hover': {
                  boxShadow: themes[theme].buttonHover,
                  background: themes[theme].button,
                },
              }}
              onClick={() => setIsMapMobile(!isMapMobile)}
            >
              {isMapMobile ? <TableRowsIcon /> : <MapIcon />}
              <span>&nbsp;{isMapMobile ? 'Lista' : 'Mapa'}</span>
            </Button>
            <Button
              variant='contained'
              sx={{
                visibility: isFilterActive() ? 'visible' : 'hidden',
                height: '40px',
                padding: '10px 22px',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 'normal',
                color: themes[theme].cancelButtonText,
                bgcolor: themes[theme].cancelButtonBg,
                border: `1px solid ${themes[theme].cancelButtonBorder}`,
                borderRadius: '20px',
                boxShadow: 'none',
                '&:hover': {
                  color: themes[theme].cancelButtonText,
                  bgcolor: themes[theme].cancelButtonBg,
                  border: `1px solid ${themes[theme].cancelButtonBorder}`,
                  boxShadow: 'none',
                },
              }}
              onClick={() => resetFilters()}
            >
              Poništi
              <br />
              filtere
            </Button>
            {showAllFilters ? (
              <div className='filters-arrow' onClick={() => setShowAllFilters(false)}>
                <span>Manje filtera</span>
                <ExpandLess />
              </div>
            ) : (
              <div className='filters-arrow' onClick={() => setShowAllFilters(true)}>
                <span>Više filtera</span>
                <ExpandMore />
              </div>
            )}
          </div>
          <AdditionalFilters />
        </ThemeProvider>
      </div>
    );
  };

  return (
    <div className='report-map'>
      <div className='map-filters-mobile'>
        <MapFilters />
      </div>
      <div className={`map-container${isMapMobile ? '' : ' hide-map-mobile'}`}>
        <MapContainer id='map' ref={mapRef} scrollWheelZoom={true}>
          <div className='map-overlay'>
            <div className='contracts-number'>
              Broj ugovora:&nbsp;
              <span>{getFilteredContracts.length}</span>
            </div>
            <div className='contracts-number'>
              Cena kvadrata:&nbsp;
              {getFilteredContracts.length ? (
                <span>
                  {Math.round(
                    getFilteredContracts
                      .filter((c) => c.eligible)
                      .reduce((a, b) => a + b.squarePrice, 0) /
                      getFilteredContracts.filter((c) => c.eligible).length,
                  ) || ''}{' '}
                  €/m²
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <MarkerClusterGroup
            chunkedLoading
            spiderfyDistanceMultiplier={1.4}
            polygonOptions={{ color: themes[theme].main }}
          >
            {getFilteredContracts.map((c) => {
              return (
                <Marker key={c.id} position={[c.lat, c.long]} icon={generateIcon(c)}>
                  <Popup>
                    <div className='popup-container'>
                      <div className='popup-row'>
                        <div className='date'>{formatDate(c.date)}</div>
                        <div className='date'>{getTypeName(c.type)}</div>
                      </div>
                      <div className='location'>{c.location}</div>
                      <div className={`price type-${c.type}`}>{formatEur(c.price)}</div>
                      <div className='popup-row'>
                        <div className='area'>{c.area} m²</div>
                        <div className='area'>{getValidSquarePrice(c.squarePrice)}</div>
                      </div>
                      {c.subjects.map((s, i) => {
                        return (
                          <div key={`${c.id}-s-${i}`} className='subject'>
                            <div>{s.type}</div>
                            <div>&nbsp;({s.area} m²)</div>
                            {(c.lat !== s.lat || c.long !== s.long) && (
                              <SubjectLocation lat={s.lat} long={s.long} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Popup>
                </Marker>
              );
            })}
          </MarkerClusterGroup>
          <FullscreenControl
            position='topright'
            forceSeparateButton={true}
            content={renderToString(<FullscreenIcon className='full-screen-button' />)}
            title='Ceo ekran'
            titleCancel='Izađi iz celog ekrana'
          />
          <MarkerBounds />
          <FeatureGroup>
            <EditControl
              position='topright'
              onCreated={(e) => savePolygon(e.layer)}
              draw={{
                polyline: false,
                circle: false,
                polygon: {
                  shapeOptions: {
                    color: themes[theme].main,
                  },
                },
                rectangle: false,
                circlemarker: false,
                marker: false,
              }}
              edit={{
                edit: false,
                remove: false,
              }}
            />
            <Circle center={[51.51, -0.06]} radius={100} />
          </FeatureGroup>
          {!!polygons.length && (
            <div className='remove-polygon' onClick={deletePolygons}>
              <span>Izbriši oblasti pretrage</span>
              <DeleteIcon fontSize='small' />
            </div>
          )}
        </MapContainer>
      </div>
      <div className={`map-right-section${isMapMobile ? ' hide-contracts-mobile' : ''}`}>
        <MapFilters />
        <ReportContractsSimple
          contracts={getFilteredContracts}
          loading={false}
          showOnMap={onShowOnMap}
        />
      </div>
    </div>
  );
}
