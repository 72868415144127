import React, { useContext, useEffect, useRef } from 'react';
import ReactGA4 from 'react-ga4';
import { Grid, Tooltip as MuiTooltip, Typography, useMediaQuery } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ThemeContext } from '../../../contexts/ThemeContext';
import {
  calculateApartmentsAreaData,
  calculateApartmentsPriceData,
  calculateApartmentsSquarePriceData,
  formatEurCompact,
  shortDate,
} from '../../../helpers/helpers';
import {
  areaTooltipText,
  priceTooltipText,
  squarePriceTooltipText,
  themes,
  totalPriceTooltipText,
} from '../../../helpers/constants';

import './ReportCharts.scss';

export default function ReportCharts({ data, contracts, scrollToRef }) {
  const { theme } = useContext(ThemeContext);
  const totalCountRef = useRef();
  const apartmentsCountRef = useRef();
  const [totalBarShow, setTotalBarShow] = React.useState(true);
  const [apartmentsAreaData, setApartmentsAreaData] = React.useState(null);
  const [apartmentsSquarePriceData, setApartmentsSquarePriceData] = React.useState(null);
  const [apartmentsPriceData, setApartmentsPriceData] = React.useState(null);

  useEffect(() => {
    ReactGA4.send({ hitType: 'pageview', page: '/trend', title: 'Trend' });
  }, []);

  useEffect(() => {
    if (contracts.length) {
      setApartmentsAreaData(calculateApartmentsAreaData(contracts));
      setApartmentsSquarePriceData(calculateApartmentsSquarePriceData(contracts));
      setApartmentsPriceData(calculateApartmentsPriceData(contracts));
    } else {
      setApartmentsAreaData(null);
      setApartmentsSquarePriceData(null);
      setApartmentsPriceData(null);
    }
  }, [contracts]);

  useEffect(() => {
    switch (scrollToRef) {
      case 'newSquarePrice':
      case 'oldSquarePrice':
      case 'squarePrice':
        window.scrollTo(0, 0);
        break;
      case 'totalCount':
        totalCountRef.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
        break;
      case 'newApartmentsCount':
      case 'oldApartmentsCount':
        apartmentsCountRef.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
        break;
      default:
        break;
    }
  }, [scrollToRef]);

  return (
    <div className='report-charts'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h1>Cena kvadrata (€/m²)</h1>
              <MuiTooltip title={<Typography fontSize={14}>{squarePriceTooltipText}</Typography>}>
                <InfoOutlinedIcon />
              </MuiTooltip>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 250 : 400}
            >
              <LineChart data={data} margin={{ top: 10, right: 15, left: 0, bottom: 15 }}>
                <CartesianGrid stroke={themes[theme].border} />
                <XAxis
                  dataKey='id'
                  interval={0}
                  stroke={themes[theme].textSecondary}
                  tickFormatter={shortDate}
                  tick={{ fontSize: 10, fill: themes[theme].textSecondary }}
                  minTickGap={0}
                  angle={-45}
                  textAnchor='end'
                />
                <YAxis
                  tickCount={7}
                  type='number'
                  domain={['auto', 'auto']}
                  stroke={themes[theme].textSecondary}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <Tooltip
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='newSquarePrice'
                  name='Novogradnja'
                  stroke={themes[theme].new}
                />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='oldSquarePrice'
                  name='Starogradnja'
                  stroke={themes[theme].old}
                />
                <Line
                  strokeWidth={4}
                  dot={false}
                  connectNulls
                  dataKey='squarePrice'
                  name='Prosek'
                  stroke={themes[theme].main}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid ref={totalCountRef} item xs={12}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Broj prometa</h2>
              <MuiTooltip
                title={
                  <Typography fontSize={14}>
                    Broj prometa predstavlja broj kupoprodajnih ugovora u kojima se nalazi barem
                    jedan stan.
                  </Typography>
                }
              >
                <InfoOutlinedIcon />
              </MuiTooltip>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 250 : 400}
            >
              <BarChart data={data} margin={{ top: 10, right: 15, left: 0, bottom: 15 }}>
                <CartesianGrid vertical={false} stroke={themes[theme].border} />
                <XAxis
                  dataKey='id'
                  interval={0}
                  stroke={themes[theme].textSecondary}
                  tickFormatter={shortDate}
                  tick={{ fontSize: 10, fill: themes[theme].textSecondary }}
                  minTickGap={0}
                  angle={-45}
                  textAnchor='end'
                />
                <YAxis
                  stroke={themes[theme].textSecondary}
                  tickCount={7}
                  type='number'
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <Tooltip
                  cursor={{ fill: themes[theme].border }}
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' onClick={() => setTotalBarShow(!totalBarShow)} />
                <Bar
                  dataKey='totalCount'
                  name='Ugovori'
                  fill={themes[theme].main}
                  radius={[6, 6, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} className={!contracts.length ? 'hide-graph' : ''}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Broj stanova po ceni kvadrata (€/m²)</h2>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 350 : 600}
            >
              <BarChart
                layout={'vertical'}
                data={apartmentsSquarePriceData}
                margin={{ top: 10, right: 5, left: 25, bottom: 0 }}
              >
                <CartesianGrid horizontal={false} stroke={themes[theme].border} />
                <YAxis
                  dataKey='id'
                  interval={0}
                  type='category'
                  stroke={themes[theme].textSecondary}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <XAxis
                  type='number'
                  stroke={themes[theme].textSecondary}
                  tickCount={10}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                  domain={['auto', 'auto']}
                />
                <Tooltip
                  cursor={{ fill: themes[theme].border }}
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' onClick={() => setTotalBarShow(!totalBarShow)} />
                <Bar dataKey='new' name='Novogradnja' stackId='a' fill={themes[theme].new} />
                <Bar dataKey='old' name='Starogradnja' stackId='a' fill={themes[theme].old} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} className={!contracts.length ? 'hide-graph' : ''}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Broj stanova po ceni (€)</h2>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 350 : 600}
            >
              <BarChart
                layout={'vertical'}
                data={apartmentsPriceData}
                margin={{ top: 10, right: 5, left: 15, bottom: 0 }}
              >
                <CartesianGrid horizontal={false} stroke={themes[theme].border} />
                <YAxis
                  dataKey='id'
                  interval={0}
                  type='category'
                  stroke={themes[theme].textSecondary}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <XAxis
                  type='number'
                  stroke={themes[theme].textSecondary}
                  tickCount={10}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                  domain={['auto', 'auto']}
                />
                <Tooltip
                  cursor={{ fill: themes[theme].border }}
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' onClick={() => setTotalBarShow(!totalBarShow)} />
                <Bar dataKey='new' name='Novogradnja' stackId='a' fill={themes[theme].new} />
                <Bar dataKey='old' name='Starogradnja' stackId='a' fill={themes[theme].old} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12} className={!contracts.length ? 'hide-graph' : ''}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Broj stanova po kvadraturi (m²)</h2>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 250 : 400}
            >
              <BarChart
                layout={'vertical'}
                data={apartmentsAreaData}
                margin={{ top: 10, right: 5, left: 0, bottom: 0 }}
              >
                <CartesianGrid horizontal={false} stroke={themes[theme].border} />
                <YAxis
                  dataKey='id'
                  interval={0}
                  type='category'
                  stroke={themes[theme].textSecondary}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <XAxis
                  type='number'
                  stroke={themes[theme].textSecondary}
                  tickCount={10}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                  domain={['auto', 'auto']}
                />
                <Tooltip
                  cursor={{ fill: themes[theme].border }}
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' onClick={() => setTotalBarShow(!totalBarShow)} />
                <Bar dataKey='new' name='Novogradnja' stackId='a' fill={themes[theme].new} />
                <Bar dataKey='old' name='Starogradnja' stackId='a' fill={themes[theme].old} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Cena stana (€)</h2>
              <MuiTooltip title={<Typography fontSize={14}>{priceTooltipText}</Typography>}>
                <InfoOutlinedIcon />
              </MuiTooltip>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 250 : 400}
            >
              <LineChart data={data} margin={{ top: 10, right: 5, left: 0, bottom: 15 }}>
                <CartesianGrid stroke={themes[theme].border} />
                <XAxis
                  dataKey='id'
                  interval={0}
                  stroke={themes[theme].textSecondary}
                  tickFormatter={shortDate}
                  tick={{ fontSize: 10, fill: themes[theme].textSecondary }}
                  minTickGap={0}
                  angle={-45}
                  textAnchor='end'
                />
                <YAxis
                  tickCount={7}
                  type='number'
                  domain={['auto', 'auto']}
                  stroke={themes[theme].textSecondary}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <Tooltip
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='newPrice'
                  name='Novogradnja'
                  stroke={themes[theme].new}
                />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='oldPrice'
                  name='Starogradnja'
                  stroke={themes[theme].old}
                />
                <Line
                  strokeWidth={4}
                  dot={false}
                  connectNulls
                  dataKey='price'
                  name='Prosek'
                  stroke={themes[theme].main}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid ref={apartmentsCountRef} item xs={12}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Broj stanova</h2>
              <MuiTooltip
                title={
                  <Typography fontSize={14}>
                    Ukupan broj novogradnji i starogradnji u kupoprodajnim ugovorima.
                  </Typography>
                }
              >
                <InfoOutlinedIcon />
              </MuiTooltip>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 250 : 400}
            >
              <BarChart data={data} margin={{ top: 10, right: 5, left: 0, bottom: 15 }}>
                <CartesianGrid vertical={false} stroke={themes[theme].border} />
                <XAxis
                  dataKey='id'
                  interval={0}
                  stroke={themes[theme].textSecondary}
                  tickFormatter={shortDate}
                  tick={{ fontSize: 10, fill: themes[theme].textSecondary }}
                  minTickGap={0}
                  angle={-45}
                  textAnchor='end'
                />
                <YAxis
                  tickCount={7}
                  type='number'
                  stroke={themes[theme].textSecondary}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <Tooltip
                  cursor={{ fill: themes[theme].border }}
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' onClick={() => setTotalBarShow(!totalBarShow)} />
                <Bar
                  dataKey='newApartmentsCount'
                  name='Novogradnja'
                  fill={themes[theme].new}
                  radius={[6, 6, 0, 0]}
                />
                <Bar
                  dataKey='oldApartmentsCount'
                  name='Starogradnja'
                  fill={themes[theme].old}
                  radius={[6, 6, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Obim prometa (€)</h2>
              <MuiTooltip title={<Typography fontSize={14}>{totalPriceTooltipText}</Typography>}>
                <InfoOutlinedIcon />
              </MuiTooltip>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 250 : 400}
            >
              <LineChart data={data} margin={{ top: 10, right: 5, left: 0, bottom: 15 }}>
                <CartesianGrid stroke={themes[theme].border} />
                <XAxis
                  dataKey='id'
                  interval={0}
                  stroke={themes[theme].textSecondary}
                  tickFormatter={shortDate}
                  tick={{ fontSize: 10, fill: themes[theme].textSecondary }}
                  minTickGap={0}
                  angle={-45}
                  textAnchor='end'
                />
                <YAxis
                  tickCount={7}
                  type='number'
                  domain={['auto', 'auto']}
                  stroke={themes[theme].textSecondary}
                  tickFormatter={formatEurCompact}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <Tooltip
                  formatter={formatEurCompact}
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='newTotalPrice'
                  name='Novogradnja'
                  stroke={themes[theme].new}
                />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='oldTotalPrice'
                  name='Starogradnja'
                  stroke={themes[theme].old}
                />
                <Line
                  strokeWidth={4}
                  dot={false}
                  connectNulls
                  dataKey='totalPrice'
                  name='Ukupno'
                  stroke={themes[theme].main}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className='chart-container'>
            <div className='chart-label'>
              <h2>Kvadratura (m²)</h2>
              <MuiTooltip title={<Typography fontSize={14}>{areaTooltipText}</Typography>}>
                <InfoOutlinedIcon />
              </MuiTooltip>
            </div>
            <ResponsiveContainer
              width='100%'
              height={useMediaQuery('(max-width: 499px)') ? 250 : 400}
            >
              <LineChart data={data} margin={{ top: 10, right: 5, left: 0, bottom: 15 }}>
                <CartesianGrid stroke={themes[theme].border} />
                <XAxis
                  dataKey='id'
                  interval={0}
                  stroke={themes[theme].textSecondary}
                  tickFormatter={shortDate}
                  tick={{ fontSize: 10, fill: themes[theme].textSecondary }}
                  minTickGap={0}
                  angle={-45}
                  textAnchor='end'
                />
                <YAxis
                  tickCount={7}
                  type='number'
                  domain={['auto', 'auto']}
                  stroke={themes[theme].textSecondary}
                  tick={{ fontSize: 12, fill: themes[theme].textSecondary }}
                />
                <Tooltip
                  contentStyle={{
                    color: themes[theme].textPrimary,
                    backgroundColor: themes[theme].bgSecondary,
                    border: `1px solid ${themes[theme].border}`,
                  }}
                />
                <Legend verticalAlign='top' />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='newArea'
                  name='Novogradnja'
                  stroke={themes[theme].new}
                />
                <Line
                  strokeWidth={3}
                  dot={false}
                  connectNulls
                  dataKey='oldArea'
                  name='Starogradnja'
                  stroke={themes[theme].old}
                />
                <Line
                  strokeWidth={4}
                  dot={false}
                  connectNulls
                  dataKey='area'
                  name='Prosek'
                  stroke={themes[theme].main}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
