import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Badge, LinearProgress, Tooltip } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BusinessIcon from '@mui/icons-material/Business';
import GarageIcon from '@mui/icons-material/Garage';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { themes } from '../../../helpers/constants';
import { formatDate, formatEur } from '../../../helpers/helpers';
import filterBetween from './filterBetween';
import { contractLocations } from './ContractLocations';

import './ReportContracts.scss';

export default function ReportContractsSimple({ contracts, loading, showOnMap }) {
  const { theme } = useContext(ThemeContext);
  const columns = useMemo(() => {
    return [
      {
        field: 'squarePrice',
        headerName: 'Cena/m²',
        type: 'number',
        valueFormatter: ({ value }) =>
          value && isFinite(value) && value > 0 ? `${formatEur(value)}/m²` : '-',
        cellClassName: (params) => (params.row.eligible ? '' : 'contract-ineligible'),
        filterOperators: filterBetween,
        width: 122,
      },
      {
        field: 'price',
        headerName: 'Cena',
        type: 'number',
        valueFormatter: ({ value }) => formatEur(value),
        filterOperators: filterBetween,
        width: 107,
      },
      {
        field: 'area',
        headerName: 'm²',
        type: 'number',
        valueFormatter: ({ value }) => `${value} m²`,
        filterOperators: filterBetween,
        width: 72,
      },
      {
        field: 'date',
        headerName: 'Datum',
        type: 'date',
        valueFormatter: ({ value }) => formatDate(value),
        width: 120,
        hide: true,
      },
      {
        field: 'subjects',
        headerName: 'Predmet',
        renderCell: ({ value }) => displayIcon(value),
        sortComparator: (v1, v2) => v1.length - v2.length,
        filterable: false,
        width: 170,
        hide: true,
      },
      {
        field: 'location',
        headerName: 'Lokacija',
        valueGetter: ({ row }) => getLocation(row),
        width: 305,
        hide: true,
      },
    ];
  }, []);

  const dataGridTheme = createTheme({
    palette: {
      primary: {
        main: themes[theme].main,
      },
    },
    typography: {
      fontFamily: `'Inter', 'Helvetica', 'Arial', sans-serif`,
    },
  });

  const GridToolbarNoExport = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <Tooltip title={<Legend />}>
          <div className='info-container'>
            <InfoOutlinedIcon />
            <span>Info</span>
          </div>
        </Tooltip>
      </GridToolbarContainer>
    );
  };

  const Legend = () => {
    return (
      <div className='report-contracts-legend'>
        <div className='legend-item type' style={{ backgroundColor: themes[theme].new }}>
          Novogradnja
        </div>
        <div className='legend-item type' style={{ backgroundColor: themes[theme].old }}>
          Starogradnja
        </div>
        <div className='legend-item type other'>Ostalo</div>
        <div className='legend-item'>
          <ApartmentIcon />
          <div>Stan u zgradi</div>
        </div>
        <div className='legend-item'>
          <HomeWorkIcon />
          <div>Stan u kući</div>
        </div>
        <div className='legend-item'>
          <BusinessIcon />
          <div>Apartman</div>
        </div>
        <div className='legend-item'>
          <GarageIcon />
          <div>Garažno mesto</div>
        </div>
        <div className='legend-item'>
          <WarehouseIcon />
          <div>Garažna prostorija</div>
        </div>
        <div className='legend-item'>
          <LocalParkingIcon />
          <div>Parking mesto</div>
        </div>
      </div>
    );
  };

  const translatePageType = (type) => {
    switch (type) {
      case 'first':
        return 'Prva';
      case 'last':
        return 'Poslednja';
      case 'next':
        return 'Sledeća';
      case 'previous':
        return 'Prethodna';
      default:
        return '';
    }
  };

  const formatContractType = (type) => {
    switch (type) {
      case '2':
        return 'new';
      case '1':
        return 'old';
      default:
        return 'other';
    }
  };

  const displayIcon = (subjects) => {
    const subjectsGrouped = _.groupBy(subjects, 'type');
    return (
      <>
        {Object.keys(subjectsGrouped).map((key) => (
          <div key={key} className='subject-icon'>
            <Tooltip
              title={`${key} (${subjectsGrouped[key].map(
                (s) => ` ${s.area >= 0 ? s.area : '-'}m²`,
              )} )`}
            >
              <Badge
                badgeContent={subjectsGrouped[key].length}
                color='primary'
                invisible={subjectsGrouped[key].length < 2}
              >
                {getIcon(key)}
              </Badge>
            </Tooltip>
          </div>
        ))}
      </>
    );
  };

  const getIcon = (type) => {
    switch (type) {
      case 'Stan u zgradi':
        return <ApartmentIcon />;
      case 'Stan u kući':
        return <HomeWorkIcon />;
      case 'Apartman':
        return <BusinessIcon />;
      case 'Garažno mesto':
        return <GarageIcon />;
      case 'Garažna prostorija':
        return <WarehouseIcon />;
      case 'Parking mesto':
        return <LocalParkingIcon />;
      default:
        return <OtherHousesIcon />;
    }
  };

  const getLocation = (row) => {
    const locationName = contractLocations.find(
      ({ startLat, startLong, endLat, endLong }) =>
        row.lat >= endLat && row.lat <= startLat && row.long >= endLong && row.long <= startLong,
    );
    return locationName ? locationName.name : '';
  };

  return (
    <div className='report-contracts simple'>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <ThemeProvider theme={dataGridTheme}>
            <DataGrid
              rows={contracts}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              disableColumnMenu
              disableColumnFilter
              headerHeight={36}
              rowHeight={48}
              loading={loading}
              hideFooterSelectedRowCount={true}
              sortingOrder={['asc', 'desc']}
              onRowClick={showOnMap}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'squarePrice', sort: 'desc' }],
                },
              }}
              sx={{
                border: 'none',
                '& .MuiDataGrid-row': {
                  bgcolor: themes[theme].bgPrimary,
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover':
                  {
                    color: '#fff',
                    bgcolor: '#636366',
                  },
                '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
                  {
                    outline: 'none',
                  },
                '& .MuiDataGrid-footerContainer': {
                  minHeight: '36px',
                  height: '36px',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  justifyContent: 'flex-end',
                },
                '& .MuiDataGrid-columnSeparator': {
                  visibility: 'hidden',
                },
                '& .MuiToolbar-root': {
                  paddingLeft: '10px',
                },
                '& .MuiDataGrid-sortIcon': {
                  color: themes[theme].textPrimary,
                },
                '& .MuiTablePagination-actions': {
                  marginLeft: '10px',
                  '& .MuiButtonBase-root': {
                    color: themes[theme].textPrimary,
                  },
                },
                '& .MuiTablePagination-displayedRows': {
                  fontSize: 12,
                  color: themes[theme].textPrimary,
                },
                fontSize: 16,
                color: themes[theme].textPrimary,
              }}
              getRowClassName={(params) => `contracts-row-${formatContractType(params.row.type)}`}
              components={{
                Toolbar: GridToolbarNoExport,
                LoadingOverlay: LinearProgress,
              }}
              localeText={{
                noRowsLabel: 'Nema ugovora',
                noResultsOverlayLabel: 'Nema rezultata.',
                errorOverlayDefaultLabel: 'Došlo je do greške.',
                toolbarDensity: 'Veličina',
                toolbarDensityLabel: 'Veličina',
                toolbarDensityCompact: 'Malo',
                toolbarDensityStandard: 'Srednje',
                toolbarDensityComfortable: 'Veliko',
                toolbarColumns: 'Kolone',
                toolbarColumnsLabel: 'Izaberi kolone',
                toolbarFilters: 'Filteri',
                toolbarFiltersLabel: 'Prikaži filtere',
                toolbarFiltersTooltipHide: 'Sakrij filtere',
                toolbarFiltersTooltipShow: 'Prikaži filtere',
                toolbarFiltersTooltipActive: (count) =>
                  count !== 1 ? `${count} filtera` : `${count} filter`,
                toolbarQuickFilterPlaceholder: 'Pretraga…',
                toolbarQuickFilterLabel: 'Pretraga',
                toolbarQuickFilterDeleteIconLabel: 'Izbriši',
                columnsPanelTextFieldLabel: 'Pronađi kolonu',
                columnsPanelTextFieldPlaceholder: 'Ime kolone',
                columnsPanelDragIconLabel: 'Preuredi kolonu',
                columnsPanelShowAllButton: 'Prikaži sve',
                columnsPanelHideAllButton: 'Sakrij sve',
                filterPanelAddFilter: 'Dodaj filter',
                filterPanelDeleteIconLabel: 'Izbriši',
                filterPanelLinkOperator: 'Logički operator',
                filterPanelOperators: 'Operator',
                filterPanelOperatorAnd: 'I',
                filterPanelOperatorOr: 'Ili',
                filterPanelColumns: 'Kolone',
                filterPanelInputLabel: 'Vrednost',
                filterPanelInputPlaceholder: 'Vrednost filtera',
                filterOperatorContains: 'sadrži',
                filterOperatorEquals: 'jednako',
                filterOperatorStartsWith: 'počinje sa',
                filterOperatorEndsWith: 'završava se sa',
                filterOperatorIs: 'na dan',
                filterOperatorNot: 'nije na dan',
                filterOperatorAfter: 'posle',
                filterOperatorOnOrAfter: 'od',
                filterOperatorBefore: 'pre',
                filterOperatorOnOrBefore: 'do',
                filterOperatorIsEmpty: 'prazan',
                filterOperatorIsNotEmpty: 'nije prazan',
                filterOperatorIsAnyOf: 'bilo koji od',
                filterValueAny: 'bilo koji',
                filterValueTrue: 'tačno',
                filterValueFalse: 'netačno',
                columnMenuLabel: 'Meni',
                columnMenuShowColumns: 'Prikaži kolone',
                columnMenuFilter: 'Filter',
                columnMenuHideColumn: 'Sakrij',
                columnMenuUnsort: 'Poništi sortiranje',
                columnMenuSortAsc: 'Sortiraj rastuće',
                columnMenuSortDesc: 'Sortiraj opadajuće',
                columnHeaderFiltersTooltipActive: (count) =>
                  count !== 1 ? `${count} filtera` : `${count} filter`,
                columnHeaderFiltersLabel: 'Prikaži filtere',
                columnHeaderSortIconLabel: 'Sortiraj',
                booleanCellTrueLabel: 'tačno',
                booleanCellFalseLabel: 'netačno',
                actionsCellMore: 'više',
                MuiTablePagination: {
                  showFirstButton: true,
                  showLastButton: true,
                  labelRowsPerPage: 'Broj redova:',
                  getItemAriaLabel: (type) => `${translatePageType(type)} strana`,
                },
              }}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
}
