import React, { useContext } from 'react';
import { Tab, Tabs } from '@mui/material';
import { ThemeContext } from '../../contexts/ThemeContext';
import { getAllContracts, getQuarterlyStatisticsData, getStatisticsData } from '../../helpers/data';
import { mapContracts } from '../../helpers/mappers';
import { months, quarters } from '../../helpers/filterConstants';
import { themes } from '../../helpers/constants';
import MainSelect from './main-select/MainSelect.js';
import ReportMain from './report-main/ReportMain.js';
import ReportCharts from './report-charts/ReportCharts';
import ReportMap from './report-map/ReportMap';
import ReportContracts from './report-contracts/ReportContracts.js';
import HeaderTertiary from '../header-tertiary/HeaderTertiary';

import './Report.scss';

export default function Report() {
  const { theme } = useContext(ThemeContext);
  const [tab, setTab] = React.useState('report');
  const [contracts, setContracts] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState('monthly');
  const [scrollToRef, setScrollToRef] = React.useState(null);

  const getData = async (selectedMonth, selectedYear, selectedRegion, selectedSubRegion) => {
    const monthString = months.find(({ value }) => value === selectedMonth).stringValue;
    const allContracts = await getAllContracts(
      monthString,
      selectedYear,
      selectedRegion,
      selectedSubRegion,
    );
    setContracts(
      mapContracts(
        allContracts.filter((c) => c.datumU.startsWith(`${selectedYear}-${monthString}`)),
      ),
    );

    const statisticsData = getStatisticsData(allContracts, selectedMonth, selectedYear);
    setType('monthly');
    setData(statisticsData);
  };

  const getQuarterlyData = async (
    selectedQuarter,
    selectedYear,
    selectedRegion,
    selectedSubRegion,
  ) => {
    const quarter = quarters.find(({ value }) => value === selectedQuarter);
    const monthString = quarter.stringValue;
    const lastMonthString = quarter.lastMonthString;
    const allContracts = await getAllContracts(
      monthString,
      selectedYear,
      selectedRegion,
      selectedSubRegion,
      lastMonthString,
    );
    setContracts(
      mapContracts(
        allContracts.filter((c) =>
          quarter.monthStrings.some((mS) => c.datumU.startsWith(`${selectedYear}-${mS}`)),
        ),
      ),
    );

    const statisticsData = getQuarterlyStatisticsData(allContracts, selectedQuarter, selectedYear);
    setType('quarterly');
    setData(statisticsData);
  };

  const changeTab = (value, scrolltoRef) => {
    setScrollToRef(scrolltoRef);
    setTab(value);
  };

  const tabsStyle =
    theme === 'dark'
      ? {
          marginBottom: '8px',
          '@media screen and (max-width: 599px)': {
            margin: '0 8px 8px 8px',
          },
          '& .MuiButtonBase-root': {
            minWidth: '64px',
            fontSize: '1rem',
            fontWeight: 400,
            color: '#fff',
          },
          '& .MuiButtonBase-root.Mui-selected': {
            color: themes[theme].main,
          },
          '& .MuiTabs-indicator': {
            bottom: '8px',
            bgcolor: themes[theme].main,
          },
        }
      : {
          marginBottom: '8px',
          border: '1px solid #dfdfdd',
          borderRadius: '12px',
          '& .MuiButtonBase-root.Mui-selected': {
            color: '#fff',
            bgcolor: '#636366',
            '&:hover': {
              bgcolor: '#636366',
            },
          },
          '@media screen and (max-width: 599px)': {
            margin: '0 8px 8px 8px',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
          '& .MuiButtonBase-root': {
            minWidth: '64px',
            fontSize: '1rem',
            fontWeight: 400,
            color: '#2C2C2E',
            bgcolor: '#fff',
            '&:hover': {
              bgcolor: '#F2F2F7',
            },
          },
          '& .MuiButtonBase-root:not(:last-child)': {
            borderRight: '1px solid #dfdfdd',
          },
        };

  return (
    <div className='report-container'>
      <MainSelect getData={getData} getQuarterlyData={getQuarterlyData} />
      <HeaderTertiary />
      <div className='report-content'>
        <Tabs
          value={tab}
          onChange={(_e, newValue) => changeTab(newValue, null)}
          variant='fullWidth'
          sx={tabsStyle}
        >
          <Tab value='report' label='Izveštaj' />
          <Tab value='charts' label='Trend' />
          <Tab value='map' label='Mapa' />
          <Tab value='contracts' label='Ugovori' />
        </Tabs>
        {tab === 'report' && data.length > 0 && (
          <ReportMain data={data} type={type} onChangeTab={changeTab} />
        )}
        {tab === 'charts' && data.length > 0 && (
          <ReportCharts data={data} contracts={contracts} scrollToRef={scrollToRef} />
        )}
        {tab === 'map' && data.length > 0 && <ReportMap contracts={contracts} />}
        {tab === 'contracts' && data.length > 0 && (
          <ReportContracts contracts={contracts} loading={false} />
        )}
      </div>
    </div>
  );
}
