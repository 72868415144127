import { mapStatisticsData } from './mappers.js';

const langmap = {
  А: 'A',
  Б: 'B',
  В: 'V',
  Г: 'G',
  Д: 'D',
  Ђ: 'Đ',
  Е: 'E',
  Ж: 'Ž',
  З: 'Z',
  И: 'I',
  Ј: 'J',
  К: 'K',
  Л: 'L',
  Љ: 'Lj',
  М: 'M',
  Н: 'N',
  Њ: 'Nj',
  О: 'O',
  П: 'P',
  Р: 'R',
  С: 'S',
  Т: 'T',
  Ћ: 'Ć',
  У: 'U',
  Ф: 'F',
  Х: 'H',
  Ц: 'C',
  Ч: 'Č',
  Џ: 'Dž',
  Ш: 'Š',
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  ђ: 'đ',
  е: 'e',
  ж: 'ž',
  з: 'z',
  и: 'i',
  ј: 'j',
  к: 'k',
  л: 'l',
  љ: 'lj',
  м: 'm',
  н: 'n',
  њ: 'nj',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  ћ: 'ć',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'č',
  џ: 'dž',
  ш: 'š',
};

export const calculateStatisticsData = (month, year, contracts) => {
  const minArea = 17;
  const totalCount = contracts.length;
  let newCount = 0;
  let oldCount = 0;
  let newApartmentsCount = 0;
  let oldApartmentsCount = 0;
  let newTotalApartmentsCount = 0;
  let oldTotalApartmentsCount = 0;
  let newSquarePrices = 0;
  let oldSquarePrices = 0;
  let newPrices = 0;
  let oldPrices = 0;
  let newAreas = 0;
  let oldAreas = 0;

  for (const c of contracts) {
    let totalArea = 0;
    let totalGarageArea = 0;
    let apartmentsCount = 0;
    let eligible = true;
    c.n.forEach((subj) => {
      if (subj.vNepNaziv === 'стан') {
        totalArea += subj.pov;
        apartmentsCount++;
      } else if (subj.vNepNaziv === 'гаражни простор') {
        if (subj.pvNepNaziv === 'Гаражно место') {
          totalGarageArea += 6;
        } else if (subj.pvNepNaziv === 'Паркинг место ван објекта') {
          totalGarageArea += 4;
        } else if (subj.pvNepNaziv === 'Гаражна просторија') {
          totalGarageArea += 8;
        }
      }
      if (
        (subj.vNepNaziv === 'стан' && (subj.pov < minArea || subj.pov > 9999)) ||
        (subj.vNepNaziv !== 'стан' &&
          subj.vNepNaziv !== 'гаражни простор' &&
          subj.pvNepNaziv !== 'Други део стамбене зграде')
      ) {
        eligible = false;
      }
    });
    if (c.ppNaziv === '2') {
      newTotalApartmentsCount += apartmentsCount;
    } else if (c.ppNaziv === '1') {
      oldTotalApartmentsCount += apartmentsCount;
    }
    if (eligible && totalArea >= minArea && c.cenaEUR !== null && c.cenaEUR > 0) {
      if (c.ppNaziv === '2') {
        newPrices += c.cenaEUR;
        newSquarePrices += c.cenaEUR / (totalArea + totalGarageArea);
        newAreas += totalArea;
        newCount++;
        newApartmentsCount += apartmentsCount;
      } else if (c.ppNaziv === '1') {
        oldPrices += c.cenaEUR;
        oldSquarePrices += c.cenaEUR / (totalArea + totalGarageArea);
        oldAreas += totalArea;
        oldCount++;
        oldApartmentsCount += apartmentsCount;
      }
    }
  }

  return mapStatisticsData({
    month,
    year,
    totalCount,
    newSquarePrices,
    oldSquarePrices,
    newPrices,
    oldPrices,
    newAreas,
    oldAreas,
    newCount,
    oldCount,
    newApartmentsCount,
    oldApartmentsCount,
    newTotalApartmentsCount,
    oldTotalApartmentsCount,
  });
};

export const formatEur = (value) => {
  return value
    ? new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
      }).format(value)
    : 'Nema prometa';
};

export const formatEurCompact = (value) => {
  return value
    ? new Intl.NumberFormat('en-DE', {
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(value)
    : 0;
};

export const formatNumber = (number, compact = false) => {
  const options = compact
    ? { notation: 'compact', compactDisplay: 'short', maximumFractionDigits: 2 }
    : { maximumFractionDigits: 0 };
  return new Intl.NumberFormat('en-DE', options).format(number);
};

export const formatDate = (date) => {
  return date.split('-').reverse().join('.').concat('.');
};

export const shortDate = (value) => {
  return `${value.slice(0, 3).toUpperCase()} '${value.slice(-2)}`;
};

export const calculateDistance = (lat1, lon1, lat2, lon2, unit = 'K') => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist = dist * 1.609344;
  }
  if (unit === 'M') {
    dist = dist * 0.8684;
  }
  return dist;
};

export const convertCyrillicToLatin = (str) => {
  // eslint-disable-next-line
  return capitalizeFirstLetter(str.replace(/[^\u0000-\u007E]/g, (a) => langmap[a] || a));
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export const isPointInPolygon = (latitude, longitude, polygon) => {
  if (typeof latitude !== 'number' || typeof longitude !== 'number') {
    throw new TypeError('Invalid latitude or longitude. Numbers are expected');
  } else if (!polygon || !Array.isArray(polygon)) {
    throw new TypeError('Invalid polygon. Array with locations expected');
  } else if (polygon.length === 0) {
    throw new TypeError('Invalid polygon. Non-empty Array expected');
  }

  const x = latitude;
  const y = longitude;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const calculateApartmentsAreaData = (contracts) => {
  const distribution = {
    '17-30': {
      new: 0,
      old: 0,
      other: 0,
    },
    '30-40': {
      new: 0,
      old: 0,
      other: 0,
    },
    '40-50': {
      new: 0,
      old: 0,
      other: 0,
    },
    '50-60': {
      new: 0,
      old: 0,
      other: 0,
    },
    '60-70': {
      new: 0,
      old: 0,
      other: 0,
    },
    '70-80': {
      new: 0,
      old: 0,
      other: 0,
    },
    '80-90': {
      new: 0,
      old: 0,
      other: 0,
    },
    '90-100': {
      new: 0,
      old: 0,
      other: 0,
    },
    '100+': {
      new: 0,
      old: 0,
      other: 0,
    },
  };

  for (const item of contracts) {
    const area = item.area;
    if (item.type === '1') {
      if (area >= 17 && area < 30) {
        distribution['17-30'].old++;
      } else if (area < 40) {
        distribution['30-40'].old++;
      } else if (area < 50) {
        distribution['40-50'].old++;
      } else if (area < 60) {
        distribution['50-60'].old++;
      } else if (area < 70) {
        distribution['60-70'].old++;
      } else if (area < 80) {
        distribution['70-80'].old++;
      } else if (area < 90) {
        distribution['80-90'].old++;
      } else if (area < 100) {
        distribution['90-100'].old++;
      } else if (area >= 100) {
        distribution['100+'].old++;
      }
    } else if (item.type === '2') {
      if (area >= 17 && area < 30) {
        distribution['17-30'].new++;
      } else if (area < 40) {
        distribution['30-40'].new++;
      } else if (area < 50) {
        distribution['40-50'].new++;
      } else if (area < 60) {
        distribution['50-60'].new++;
      } else if (area < 70) {
        distribution['60-70'].new++;
      } else if (area < 80) {
        distribution['70-80'].new++;
      } else if (area < 90) {
        distribution['80-90'].new++;
      } else if (area < 100) {
        distribution['90-100'].new++;
      } else if (area >= 100) {
        distribution['100+'].new++;
      }
    } else {
      if (area >= 17 && area < 30) {
        distribution['17-30'].other++;
      } else if (area < 40) {
        distribution['30-40'].other++;
      } else if (area < 50) {
        distribution['40-50'].other++;
      } else if (area < 60) {
        distribution['50-60'].other++;
      } else if (area < 70) {
        distribution['60-70'].other++;
      } else if (area < 80) {
        distribution['70-80'].other++;
      } else if (area < 90) {
        distribution['80-90'].other++;
      } else if (area < 100) {
        distribution['90-100'].other++;
      } else if (area >= 100) {
        distribution['100+'].other++;
      }
    }
  }

  const distributionArray = Object.entries(distribution).map(([key, value]) => ({
    id: key,
    new: value.new,
    old: value.old,
    other: value.other,
  }));

  return distributionArray;
};

export const calculateApartmentsSquarePriceData = (contracts) => {
  const distribution = {};
  const sortedContracts = [...contracts].sort((a, b) => a.squarePrice - b.squarePrice);
  let step = 200;

  for (const item of sortedContracts) {
    if (item.squarePrice >= 7000) {
      step = 1000;
    } else if (item.squarePrice >= 5000) {
      step = 500;
    }
    const rangeStart = Math.floor(item.squarePrice / step) * step;
    const rangeKey = `${rangeStart}-${rangeStart + step}`;

    if (item.type === '1') {
      if (!distribution[rangeKey]) {
        distribution[rangeKey] = {
          new: 0,
          old: 0,
          other: 0,
        };
      }
      distribution[rangeKey].old++;
    } else if (item.type === '2') {
      if (!distribution[rangeKey]) {
        distribution[rangeKey] = {
          new: 0,
          old: 0,
          other: 0,
        };
      }
      distribution[rangeKey].new++;
    } else {
      if (!distribution[rangeKey]) {
        distribution[rangeKey] = {
          new: 0,
          old: 0,
          other: 0,
        };
      }
      distribution[rangeKey].other++;
    }
  }

  const distributionArray = Object.entries(distribution).map(([key, value]) => ({
    id: key,
    new: value.new,
    old: value.old,
    other: value.other,
  }));

  return distributionArray;
};

export const calculateApartmentsPriceData = (contracts) => {
  const distribution = {};
  const sortedContracts = [...contracts].sort((a, b) => a.price - b.price);
  let step = 20000;

  for (const item of sortedContracts) {
    if (item.price >= 1000000) {
      step = 500000;
    } else if (item.price >= 400000) {
      step = 100000;
    }
    const rangeStart = Math.floor(item.price / step) * step;
    const rangeKey = `${formatEurCompact(rangeStart)}-${formatEurCompact(rangeStart + step)}`;

    if (item.type === '1') {
      if (!distribution[rangeKey]) {
        distribution[rangeKey] = {
          new: 0,
          old: 0,
          other: 0,
        };
      }
      distribution[rangeKey].old++;
    } else if (item.type === '2') {
      if (!distribution[rangeKey]) {
        distribution[rangeKey] = {
          new: 0,
          old: 0,
          other: 0,
        };
      }
      distribution[rangeKey].new++;
    } else {
      if (!distribution[rangeKey]) {
        distribution[rangeKey] = {
          new: 0,
          old: 0,
          other: 0,
        };
      }
      distribution[rangeKey].other++;
    }
  }

  const distributionArray = Object.entries(distribution).map(([key, value]) => ({
    id: key,
    new: value.new,
    old: value.old,
    other: value.other,
  }));

  return distributionArray;
};
