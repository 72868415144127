import { contractLocations } from '../components/report/report-contracts/ContractLocations';
import { convertCyrillicToLatin } from './helpers';

const getTotalAreas = (contract) => {
  let totalArea = 0;
  let totalGarageArea = 0;
  contract.n.forEach((subject) => {
    if (subject.vNepNaziv === 'стан') {
      totalArea += subject.pov;
    } else if (subject.vNepNaziv === 'гаражни простор') {
      if (subject.pvNepNaziv === 'Гаражно место') {
        totalGarageArea += 6;
      } else if (subject.pvNepNaziv === 'Паркинг место ван објекта') {
        totalGarageArea += 4;
      } else if (subject.pvNepNaziv === 'Гаражна просторија') {
        totalGarageArea += 8;
      }
    }
  });
  return [totalArea, totalArea + totalGarageArea];
};

const mapContractSubjects = (contract) => {
  const subjectTypes = {
    'Стан у стамбеној  згради за колективно становање': 'Stan u zgradi',
    'Стан у кући': 'Stan u kući',
    апартман: 'Apartman',
    'Гаражно место': 'Garažno mesto',
    'Гаражна просторија': 'Garažna prostorija',
    'Паркинг место ван објекта': 'Parking mesto',
    'Други део стамбене зграде': 'Drugi deo zgrade',
  };
  return contract.n.map((subject) => {
    return {
      mainType: subjectTypes[subject.pvNepNaziv] || 'Ostalo',
      type:
        subjectTypes[subject.pvNepNaziv] ||
        convertCyrillicToLatin(subject.pvNepNaziv) ||
        convertCyrillicToLatin(subject.vNepNaziv),
      area: subject.pov,
      lat: subject.latlon.Lat,
      long: subject.latlon.Lon,
    };
  });
};

export const mapStatisticsData = ({
  month,
  year,
  totalCount,
  newSquarePrices,
  oldSquarePrices,
  newPrices,
  oldPrices,
  newAreas,
  oldAreas,
  newCount,
  oldCount,
  newApartmentsCount,
  oldApartmentsCount,
  newTotalApartmentsCount,
  oldTotalApartmentsCount,
}) => {
  const squarePrices = newSquarePrices + oldSquarePrices;
  const prices = newPrices + oldPrices;
  const areas = newAreas + oldAreas;
  const count = newCount + oldCount;
  const apartmentsCount = newApartmentsCount + oldApartmentsCount;
  const totalApartmentsCount = newTotalApartmentsCount + oldTotalApartmentsCount;

  return {
    id: `${month.label} ${year.label}`,
    totalCount: totalCount,
    count: count,
    apartmentsCount: totalApartmentsCount,
    squarePrice: Math.round(squarePrices / count) || '-',
    area: Math.round(areas / apartmentsCount) || '-',
    price: Math.round(prices / apartmentsCount) || '-',
    totalPrice: newPrices + oldPrices,
    newCount: newCount,
    newApartmentsCount: newTotalApartmentsCount,
    newSquarePrice: Math.round(newSquarePrices / newCount) || '-',
    newArea: Math.round(newAreas / newApartmentsCount) || '-',
    newPrice: Math.round(newPrices / newApartmentsCount) || '-',
    newTotalPrice: newPrices,
    oldCount: oldCount,
    oldApartmentsCount: oldTotalApartmentsCount,
    oldSquarePrice: Math.round(oldSquarePrices / oldCount) || '-',
    oldArea: Math.round(oldAreas / oldApartmentsCount) || '-',
    oldPrice: Math.round(oldPrices / oldApartmentsCount) || '-',
    oldTotalPrice: oldPrices,
  };
};

export const mapContracts = (contracts) => {
  const res = [];
  for (const contract of contracts) {
    const [totalArea, totalAreaWithGarages] = getTotalAreas(contract);
    const lat = contract.n[0].latlon.Lat;
    const long = contract.n[0].latlon.Lon;
    res.push({
      id: contract.id,
      date: contract.datumU,
      price: contract.cenaEUR,
      area: totalArea,
      squarePrice: Math.round(contract.cenaEUR / totalAreaWithGarages),
      type: contract.ppNaziv,
      subjects: mapContractSubjects(contract),
      lat: lat,
      long: long,
      location: getLocation(lat, long),
      eligible: isContractEligible(contract),
    });
  }

  return res;
};

const isContractEligible = (c) => {
  if (c.ppNaziv !== '1' && c.ppNaziv !== '2') {
    return false;
  }
  if (
    c.cenaEUR < 1 ||
    c.n.some(
      (subj) =>
        (subj.vNepNaziv !== 'стан' &&
          subj.vNepNaziv !== 'гаражни простор' &&
          subj.pvNepNaziv !== 'Други део стамбене зграде') ||
        (subj.vNepNaziv === 'стан' && (subj.pov < 17 || subj.pov > 9999)),
    )
  ) {
    return false;
  }
  return true;
};

const getLocation = (lat, long) => {
  const locationName = contractLocations.find(
    ({ startLat, startLong, endLat, endLong }) =>
      lat >= endLat && lat <= startLat && long >= endLong && long <= startLong,
  );
  return locationName ? locationName.name : '';
};
