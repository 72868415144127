import React, { useEffect } from 'react';
import ReactGA4 from 'react-ga4';

import './About.scss';

export default function About() {
  useEffect(() => {
    ReactGA4.event({
      category: 'Page',
      action: 'About',
    });
  }, []);

  return (
    <div className='about-container'>
      <div className='about'>
        <h1 className='important-text'>CENE NEKRETNINA</h1> je platforma koja se bavi statistikom
        cena stanova iz kupoprodajnih ugovora.
        <br />
        U statistiku ulaze realizovane cene, a koriste se javno dostupni podaci iz Registra cena
        nepokretnosti RGZ-a.
        <br />
        Svi podaci su informativnog karaktera.
        <br />
        Ukoliko želite da saznate koja je realna prosečna cena stana na određenoj lokaciji, na
        pravom ste mestu!
        <br />
        Sa ovde dostupnim informacijama možete bolje proceniti stanje na tržištu i adekvatno se
        prilagoditi.
        <br />
        Za sve informacije, pitanja, sugestije, prijavu problema, možete se obratiti putem mejla{' '}
        <a href='mailto:cenenekretnina@gmail.com'>cenenekretnina@gmail.com</a>&nbsp; ili instagram
        profila{' '}
        <a
          href='https://www.instagram.com/cenenekretnina/'
          target='_blank'
          rel='noopener noreferrer'
        >
          @cenenekretnina
        </a>
        <br />
        Zapratite nas na Instagramu kako bi ste bili blagovremeno obavešteni o novim
        funkcionalnostima.
      </div>
      <div className='details'>
        <div className='title'>Detaljnije o statistici:</div>
        <span>
          Broj prometa predstavlja broj kupoprodajnih ugovora u kojima se nalazi barem jedan stan.
          <br />U statistiku prosečnih cena ne ulaze ugovori koji pored stanova i garažnih ili
          parking mesta sadrže neku drugu vrstu nepokretnosti. Ovi ugovori se neće prikazivati u
          odeljku <span className='bold-text'>Ugovori</span>. Takođe, stanovi koji su manji od{' '}
          <span className='bold-text'>17</span> kvadratnih metara ili oni čija površina nije data u
          ugovoru ne ulaze u statistiku.
          <br />
          Sve cene su izražene u <span className='bold-text'>EUR</span> protivvrednosti. Ukoliko je
          u ugovoru navedena cena u nekoj drugoj valuti, cena će se obračunati po kursu evra na dan
          ugovora.
          <br />
          Ukoliko ugovor sadrži više od jednog stana, površina predstavlja ukupnu površinu istih.
          Ugovori mogu sadržati garažna ili parking mesta koja su uračunata u ukupnu cenu, ali ne i
          u površinu. Pri računanju cene kvadrata kod ovakvih ugovora, na ukupnu površinu dodaje se
          6m² po garažnom mestu, 4m² po parking mestu i 8m² po garažnoj prostoriji.
        </span>
      </div>
    </div>
  );
}
