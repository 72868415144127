import React, { useContext, useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { ThemeContext } from '../../contexts/ThemeContext';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import Twitter from '@mui/icons-material/Twitter';
import { supabase } from '../../api/supabase/supabaseClient';
import { formatDate } from '../../helpers/helpers';
import { logger } from '../../services/logger';
import { themes } from '../../helpers/constants';

import './Footer.scss';

export default function Footer() {
  const { theme } = useContext(ThemeContext);
  const [dataFreshness, setDataFreshness] = React.useState(null);

  useEffect(() => {
    getDataFreshness();
  }, []);

  const getDataFreshness = async () => {
    try {
      const { data: config, error } = await supabase
        .from('config')
        .select('data_freshness')
        .limit(1)
        .single();

      if (error) {
        throw error;
      }
      setDataFreshness(formatDate(config.data_freshness));
    } catch (err) {
      logger.catchError(err);
    }
  };

  const instagram = () => {
    ReactGA4.event({
      category: 'Social',
      action: 'Instagram',
    });
    window.open('https://www.instagram.com/cenenekretnina/', '_blank', 'noopener,noreferrer');
  };

  const twitter = () => {
    ReactGA4.event({
      category: 'Social',
      action: 'Twitter',
    });
    window.open('https://twitter.com/CeneNekretnina', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className='footer-container'>
      <div>
        {dataFreshness && (
          <div className='date-updated'>
            Datum ažuriranja:&nbsp;
            <span>{dataFreshness}</span>
          </div>
        )}
        <div className='date-updated'>
          Izvor:&nbsp;
          <span>Registar cena nepokretnosti RGZ-a</span>
        </div>
        <div className='email'>
          Email:&nbsp;
          <a href='mailto:cenenekretnina@gmail.com'>cenenekretnina@gmail.com</a>
        </div>
      </div>
      <div className='social-icons'>
        <IconButton onClick={instagram}>
          <InstagramIcon sx={{ color: themes[theme].footerSecondary, fontSize: '30px' }} />
        </IconButton>
        <IconButton onClick={twitter}>
          <Twitter sx={{ color: themes[theme].footerSecondary, fontSize: '30px' }} />
        </IconButton>
      </div>
    </div>
  );
}
