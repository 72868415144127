export const squarePriceTooltipText = `U statistiku prosečnih cena kvadrata ne ulaze ugovori koji pored stanova i garažnih ili parking mesta sadrže neku drugu vrstu prometa, 
  kao ni stanovi koji su manji od 17 kvadratnih metara. Ugovori mogu sadržati garažna ili parking mesta koja utiču na cenu kvadrata.`;

export const priceTooltipText = `U statistiku prosečnih cena stanova ne ulaze ugovori koji pored stanova i garažnih ili parking mesta sadrže neku drugu vrstu prometa, 
  kao ni stanovi koji su manji od 17 kvadratnih metara. Ugovori mogu sadržati garažna ili parking mesta koja utiču na cenu stana.`;

export const totalPriceTooltipText = `Obim prometa ne obuhvata ugovore koji pored stanova i garažnih ili parking mesta sadrže neku drugu vrstu prometa, 
  kao ni ugovore koji sadrže stanove koji su manji od 17 kvadratnih metara.`;

export const areaTooltipText = `U statistiku prosečnih kvadratura ne ulaze ugovori koji pored stanova i garažnih ili parking mesta sadrže neku drugu vrstu prometa, 
  kao ni stanovi koji su manji od 17 kvadratnih metara.`;

export const cn_blue = '#124cb3';
export const cn_navy_blue = '#000066';
export const cn_pink = '#ff00ff';
const blueLight = '#03a9f4';
const greenLight = '#33cc66';
const redLight = '#d9312b';
const light_black = '#202124';
const cn_black = '#2c2c2e';
const cn_gray = '#636366';
const cn_light_gray = '#808080';
export const cn_lightest_gray = '#dfdfdd';
const cn_white = '#f2f2f7';
const white = '#fff';

export const deep_purple = '#8e44ad';
export const medium_blue = '#1976d2';
export const goldenrod = '#daa520';

export const themes = {
  light: {
    main: cn_blue,
    new: cn_navy_blue,
    old: cn_pink,
    blueLight: blueLight,
    greenLight: greenLight,
    redLight: redLight,
    textPrimary: cn_black,
    textSecondary: cn_gray,
    bgPrimary: cn_white,
    bgSecondary: white,
    border: cn_lightest_gray,
    headerPrimary: cn_navy_blue,
    headerSecondary: cn_pink,
    footerPrimary: cn_navy_blue,
    footerSecondary: cn_pink,
    footerTertiary: white,
    footerBorder: 'transparent',
    button: 'linear-gradient(to right, #FF00FF, #3636B3)',
    buttonHover: '0px 6px 18px #FF00FF80',
    mapNew: cn_navy_blue,
    mapOld: cn_pink,
    mapCluster: 'rgba(18, 76, 179, .6)',
    menuButtonText: white,
    menuButtonBg: cn_blue,
    cancelButtonText: cn_blue,
    cancelButtonBg: white,
    cancelButtonBorder: cn_blue,
    loading: cn_pink,
  },
  alternate: {
    main: deep_purple,
    new: medium_blue,
    old: goldenrod,
    blueLight: blueLight,
    greenLight: greenLight,
    redLight: redLight,
    textPrimary: cn_black,
    textSecondary: cn_gray,
    bgPrimary: cn_white,
    bgSecondary: white,
    border: cn_lightest_gray,
    headerPrimary: cn_navy_blue,
    headerSecondary: white,
    footerPrimary: cn_navy_blue,
    footerSecondary: white,
    footerTertiary: cn_light_gray,
    footerBorder: 'transparent',
    button: deep_purple,
    buttonHover: 'none',
    mapNew: medium_blue,
    mapOld: goldenrod,
    mapCluster: 'rgba(18, 76, 179, .6)',
    menuButtonText: white,
    menuButtonBg: cn_blue,
    cancelButtonText: cn_black,
    cancelButtonBg: white,
    cancelButtonBorder: cn_black,
    loading: deep_purple,
  },
  dark: {
    main: deep_purple,
    new: medium_blue,
    old: goldenrod,
    blueLight: blueLight,
    greenLight: greenLight,
    redLight: redLight,
    textPrimary: '#e9eaee',
    textSecondary: cn_light_gray,
    bgPrimary: light_black,
    bgSecondary: '#2a2b2f',
    border: '#606267',
    headerPrimary: light_black,
    headerSecondary: '#e9eaee',
    footerPrimary: light_black,
    footerSecondary: '#e9eaee',
    footerTertiary: cn_light_gray,
    footerBorder: cn_black,
    button: deep_purple,
    buttonHover: 'none',
    mapNew: medium_blue,
    mapOld: goldenrod,
    mapCluster: 'rgba(142, 68, 173, .6)',
    menuButtonText: white,
    menuButtonBg: light_black,
    cancelButtonText: white,
    cancelButtonBg: cn_black,
    cancelButtonBorder: cn_black,
    loading: deep_purple,
  },
};
