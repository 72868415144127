import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import { ThemeProvider } from '@mui/material';
import { ThemeContext } from '../../contexts/ThemeContext';
import cn_logo from '../../icons/cn_logo.png';
import {
  cn_blue,
  cn_lightest_gray,
  cn_navy_blue,
  cn_pink,
  deep_purple,
  goldenrod,
  medium_blue,
  themes,
} from '../../helpers/constants';
import { darkTheme } from '../../helpers/darkTheme';

import './Header.scss';
import FormControl from '@mui/material/FormControl';

const pages = [
  { route: '/', label: 'Početna' },
  { route: '/about', label: 'Info' },
  { route: '/donate', label: 'Doniraj' },
];

export default function Header() {
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(ThemeContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <AppBar
      position='static'
      sx={{
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        bgcolor: themes[theme].footerPrimary,
        '@media screen and (max-width: 899px)': {
          height: '56px',
        },
        '.MuiToolbar-root': {
          height: '56px',
          minHeight: '56px',
        },
        '.MuiContainer-root': {
          paddingRight: '16px',
          '@media screen and (max-width: 899px)': {
            paddingLeft: '4px',
            paddingRight: '4px',
          },
        },
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <ThemeProvider theme={darkTheme(theme)}>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                disableScrollLock
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map(({ route, label }) => (
                  <MenuItem key={label} onClick={handleCloseNavMenu} component={Link} to={route}>
                    <Typography textAlign='center'>{label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </ThemeProvider>
          </Box>
          <div className='logo-container'>
            <img src={cn_logo} alt='' onClick={() => navigate('/')} />
          </div>
          <div className='title-container'>
            <span className='header-title' onClick={() => navigate('/')}>
              CENE
              <br />
              NEKRETNINA
            </span>
          </div>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ route, label }) => (
              <Button
                key={label}
                component={Link}
                to={route}
                sx={{
                  display: 'block',
                  minWidth: 'auto',
                  margin: '0 5px',
                  padding: '6px 12px',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: themes[theme].menuButtonText,
                  bgcolor: themes[theme].menuButtonBg,
                  borderRadius: '18px',
                  '&:hover': {
                    bgcolor: themes[theme].menuButtonBg,
                  },
                }}
              >
                {label}
              </Button>
            ))}
          </Box>
          <IconButton onClick={() => setDialogOpen(true)}>
            <SettingsIcon sx={{ color: themes[theme].headerSecondary, fontSize: '30px' }} />
          </IconButton>
          <ThemeProvider theme={darkTheme(theme)}>
            <Dialog open={dialogOpen} onClose={handleClose}>
              <DialogTitle sx={{ fontFamily: 'inherit', textAlign: 'center' }}>
                Izaberi temu
              </DialogTitle>
              <div className='themes-container'>
                <div
                  className='theme-container'
                  style={{
                    border:
                      theme === 'light' ? `5px solid ${cn_blue}` : `2px solid ${cn_lightest_gray}`,
                  }}
                  onClick={() => changeTheme('light')}
                >
                  <div>Osnovna</div>
                  <div className='theme-colors'>
                    <div className='theme-color' style={{ backgroundColor: cn_blue }}></div>
                    <div className='theme-color' style={{ backgroundColor: cn_navy_blue }}></div>
                    <div className='theme-color' style={{ backgroundColor: cn_pink }}></div>
                  </div>
                </div>
                <div
                  className='theme-container'
                  style={{
                    border:
                      theme === 'alternate'
                        ? `5px solid ${deep_purple}`
                        : `2px solid ${cn_lightest_gray}`,
                  }}
                  onClick={() => changeTheme('alternate')}
                >
                  <div>Alternativna</div>
                  <div className='theme-colors'>
                    <div className='theme-color' style={{ backgroundColor: deep_purple }}></div>
                    <div className='theme-color' style={{ backgroundColor: medium_blue }}></div>
                    <div className='theme-color' style={{ backgroundColor: goldenrod }}></div>
                  </div>
                </div>
                <div
                  className='theme-container dark'
                  style={{
                    border:
                      theme === 'dark'
                        ? `5px solid ${deep_purple}`
                        : `2px solid ${cn_lightest_gray}`,
                  }}
                  onClick={() => changeTheme('dark')}
                >
                  <div>Tamna</div>
                  <div className='theme-colors'>
                    <div className='theme-color' style={{ backgroundColor: deep_purple }}></div>
                    <div className='theme-color' style={{ backgroundColor: medium_blue }}></div>
                    <div className='theme-color' style={{ backgroundColor: goldenrod }}></div>
                  </div>
                </div>
              </div>
              <FormControl
                sx={{ mx: '6px', my: 1, display: 'flex', margin: '10px auto' }}
                size='small'
              >
                <Button
                  variant='contained'
                  sx={{
                    width: 'max-content',
                    height: '40px',
                    padding: '10px 22px',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textTransform: 'none',
                    color: '#fff',
                    background: themes[theme].button,
                    borderRadius: '20px',
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: themes[theme].buttonHover,
                      background: themes[theme].button,
                    },
                  }}
                  onClick={handleClose}
                >
                  Zatvori
                </Button>
              </FormControl>
            </Dialog>
          </ThemeProvider>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
