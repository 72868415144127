import React, { useContext, useEffect, useState } from 'react';
import ReactGA4 from 'react-ga4';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TuneIcon from '@mui/icons-material/Tune';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ThemeProvider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ThemeContext } from '../../../contexts/ThemeContext';
import {
  defaultMonth,
  defaultOption,
  defaultQuarter,
  defaultQuarterlyYear,
  defaultRegion,
  defaultYear,
  lastMonth,
  lastQuarter,
  lastQuarterlyYear,
  lastYear,
  months,
  quarters,
  regionsWithCities,
  subRegionsWithCities,
  years,
} from '../../../helpers/filterConstants';
import { themes } from '../../../helpers/constants';
import { darkTheme } from '../../../helpers/darkTheme';

import './MainSelect.scss';

export default function MainSelect({ getData, getQuarterlyData }) {
  const { theme } = useContext(ThemeContext);
  const [isSearch, setIsSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState('monthly');
  const [month, setMonth] = useState(defaultMonth.value);
  const [quarter, setQuarter] = useState(defaultQuarter.value);
  const [year, setYear] = useState(defaultYear.value);
  const [region, setRegion] = useState(defaultRegion.value);
  const [subRegion, setSubRegion] = useState(defaultOption.value);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, []);

  const availableMonths = () => {
    return year === lastYear.value ? months.slice(0, lastMonth.value) : months;
  };
  const availableQuarters = () => {
    return year === lastQuarterlyYear.value ? quarters.slice(0, lastQuarter.value) : quarters;
  };

  const changeYear = (newYear) => {
    if (newYear === lastYear.value && month > lastMonth.value) {
      setMonth(lastMonth.value);
    }
    if (newYear === lastQuarterlyYear.value && quarter > lastQuarter.value) {
      setQuarter(lastQuarter.value);
    }
    setYear(newYear);
  };

  const changeType = (_e, newType) => {
    if (newType) {
      setQuarter(defaultQuarter.value);
      setMonth(defaultMonth.value);
      if (newType === 'quarterly') {
        setYear(defaultQuarterlyYear.value);
      } else {
        setYear(defaultYear.value);
      }
      setType(newType);
    }
  };

  const search = async () => {
    ReactGA4.event({
      category: 'Search',
      action: getSearchAnalytics(),
    });
    setIsLoading(true);
    setIsSearch(false);
    type === 'monthly'
      ? await getData(month, year, region, subRegion)
      : await getQuarterlyData(quarter, year, region, subRegion);
    setIsLoading(false);
  };

  const changeRegion = (value) => {
    setSubRegion(defaultOption.value);
    setRegion(value);
  };

  const getSearchAnalytics = () => {
    const regionName = regionsWithCities.find((r) => r.value === region).label;
    const subRegionName = subRegionsWithCities[region].find((s) => s.value === subRegion).label;
    const period =
      type === 'monthly'
        ? months.find(({ value }) => value === month).stringValue
        : quarters.find(({ value }) => value === quarter).label;
    return `${regionName}-${subRegionName}-${year}-${period}`;
  };

  return (
    <ThemeProvider theme={darkTheme(theme)}>
      <div className='main-select'>
        {isSearch ? (
          <div className='main-select-search'>
            <FormControl sx={{ mx: '6px', my: 1, minWidth: 125 }} size='small'>
              <ToggleButtonGroup
                size='small'
                exclusive
                value={type}
                onChange={changeType}
                sx={{
                  '& .MuiButtonBase-root.Mui-selected': {
                    color: '#fff',
                    bgcolor: themes[theme].main,
                    '&:hover': {
                      bgcolor: themes[theme].main,
                    },
                  },
                }}
              >
                <ToggleButton value='monthly'>Mesečno</ToggleButton>
                <ToggleButton value='quarterly'>Kvartalno</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            {type === 'monthly' && (
              <FormControl sx={{ mx: '6px', my: 1, minWidth: 125 }} size='small'>
                <InputLabel
                  id='month-label'
                  sx={{
                    '&.Mui-focused': {
                      color: `${themes[theme].main} !important`,
                    },
                  }}
                >
                  Mesec
                </InputLabel>
                <Select
                  labelId='month-label'
                  id='month'
                  value={month}
                  label='Mesec'
                  MenuProps={{
                    style: { zIndex: 3000 },
                  }}
                  onChange={(e) => setMonth(e.target.value)}
                  sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: themes[theme].main,
                    },
                  }}
                >
                  {availableMonths().map((m) => (
                    <MenuItem key={m.value} value={m.value}>
                      {m.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {type === 'quarterly' && (
              <FormControl sx={{ mx: '6px', my: 1, minWidth: 125 }} size='small'>
                <InputLabel
                  id='month-label'
                  sx={{
                    '&.Mui-focused': {
                      color: `${themes[theme].main} !important`,
                    },
                  }}
                >
                  Kvartal
                </InputLabel>
                <Select
                  labelId='quarter-label'
                  id='quarter'
                  value={quarter}
                  label='Kvartal'
                  MenuProps={{
                    style: { zIndex: 3000 },
                  }}
                  onChange={(e) => setQuarter(e.target.value)}
                  sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: themes[theme].main,
                    },
                  }}
                >
                  {availableQuarters().map((q) => (
                    <MenuItem key={q.value} value={q.value}>
                      {q.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl sx={{ mx: '6px', my: 1, minWidth: 82 }} size='small'>
              <InputLabel
                id='year-label'
                sx={{
                  '&.Mui-focused': {
                    color: `${themes[theme].main} !important`,
                  },
                }}
              >
                Godina
              </InputLabel>
              <Select
                labelId='year-label'
                id='year'
                value={year}
                label='Godina'
                MenuProps={{
                  style: { zIndex: 3000 },
                }}
                onChange={(e) => changeYear(e.target.value)}
                sx={{
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: themes[theme].main,
                  },
                }}
              >
                {years.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mx: '6px', my: 1, minWidth: 143 }} size='small'>
              <InputLabel
                id='region-label'
                sx={{
                  '&.Mui-focused': {
                    color: `${themes[theme].main} !important`,
                  },
                }}
              >
                Opština
              </InputLabel>
              <Select
                labelId='region-label'
                id='region'
                value={region}
                label='Opština'
                MenuProps={{
                  style: { zIndex: 3000 },
                }}
                onChange={(e) => changeRegion(e.target.value)}
                sx={{
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: themes[theme].main,
                  },
                }}
              >
                {regionsWithCities.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mx: '6px', my: 1, minWidth: 186 }} size='small'>
              <InputLabel
                id='subRegion-label'
                sx={{
                  '&.Mui-focused': {
                    color: `${themes[theme].main} !important`,
                  },
                }}
              >
                Katastarska Opština
              </InputLabel>
              <Select
                labelId='subRegion-label'
                id='subRegion'
                value={subRegion}
                label='Katastarska Opština'
                MenuProps={{
                  style: { zIndex: 3000 },
                }}
                onChange={(e) => setSubRegion(e.target.value)}
                sx={{
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: themes[theme].main,
                  },
                }}
              >
                {subRegionsWithCities[region].map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              <FormControl sx={{ mx: '6px', my: 1 }} size='small'>
                <Button
                  variant='contained'
                  sx={{
                    height: '40px',
                    padding: '10px 22px',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    color: '#fff',
                    background: themes[theme].button,
                    borderRadius: '20px',
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: themes[theme].buttonHover,
                      background: themes[theme].button,
                    },
                  }}
                  onClick={search}
                >
                  Primeni
                </Button>
              </FormControl>
              <FormControl sx={{ mx: '6px', my: 1 }} size='small'>
                <Button
                  variant='contained'
                  sx={{
                    height: '40px',
                    padding: '10px 22px',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    color: themes[theme].cancelButtonText,
                    bgcolor: themes[theme].cancelButtonBg,
                    border: `1px solid ${themes[theme].cancelButtonBorder}`,
                    borderRadius: '20px',
                    boxShadow: 'none',
                    '&:hover': {
                      color: themes[theme].cancelButtonText,
                      bgcolor: themes[theme].cancelButtonBg,
                      border: `1px solid ${themes[theme].cancelButtonBorder}`,
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => setIsSearch(false)}
                >
                  Odustani
                </Button>
              </FormControl>
            </div>
          </div>
        ) : (
          <div className='main-select-title'>
            <div className='main-select-title-text'>
              <h2 className='region-text'>
                <span>{regionsWithCities.find(({ value }) => value === region).label}</span>
                {subRegion !== '-1' && <span>,&nbsp;</span>}
              </h2>
              <h2 className='region-text'>
                {subRegion !== '-1' && (
                  <span>
                    {subRegionsWithCities[region].find(({ value }) => value === subRegion).label}
                  </span>
                )}
              </h2>
              <h2 className='month-text'>
                <span className='dot-divider'>&nbsp;&#8226;&nbsp;</span>
                <span>
                  {type === 'monthly'
                    ? months.find(({ value }) => value === month).label
                    : quarters.find(({ value }) => value === quarter).label}
                  &nbsp;{years.find(({ value }) => value === year).label}
                </span>
              </h2>
              {((type === 'monthly' && year === lastYear.value && month === lastMonth.value) ||
                (type === 'quarterly' &&
                  year === lastQuarterlyYear.value &&
                  quarter === lastQuarter.value)) && (
                <div className='warning-text'>
                  <ErrorOutlineIcon />
                  <span>
                    Podaci za ovaj {type === 'monthly' ? 'mesec' : 'kvartal'} nisu konačni!
                  </span>
                </div>
              )}
            </div>
            <FormControl
              sx={{
                m: 1,
                minWidth: 226,
                alignItems: 'center',
                '@media screen and (max-width: 599px)': {
                  minWidth: 150,
                },
              }}
              size='small'
            >
              {isLoading ? (
                <CircularProgress size={40} sx={{ color: themes[theme].loading }} />
              ) : (
                <Button
                  variant='contained'
                  sx={{
                    height: '60px',
                    fontSize: '16px',
                    color: '#fff',
                    background: themes[theme].button,
                    borderRadius: '30px',
                    boxShadow: 'none',
                    '@media screen and (max-width: 599px)': {
                      height: '48px',
                      padding: '6px 8px',
                      fontSize: '14px',
                      lineHeight: 'normal',
                    },
                    '&:hover': {
                      boxShadow: themes[theme].buttonHover,
                      background: themes[theme].button,
                    },
                    div: {
                      '@media screen and (max-width: 599px)': {
                        maxWidth: '110px',
                      },
                    },
                  }}
                  onClick={() => setIsSearch(true)}
                >
                  <TuneIcon />
                  <div>&nbsp;Podešavanja pretrage</div>
                </Button>
              )}
            </FormControl>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}
