export const contractsByCityAndDate = /* GraphQL */ `
  query ContractsByGradIDAndDatumU(
    $id: Int!
    $date: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    contractsByGradIDAndDatumU(
      gradID: $id
      datumU: $date
      limit: 100000
      nextToken: $nextToken
    ) {
      items {
        id
        datumU
        ppNaziv
        cenaEUR
        n
      }
      nextToken
    }
  }
`;

export const contractsByRegionAndDate = /* GraphQL */ `
  query ContractsByOpstinaIDAndDatumU(
    $id: Int!
    $date: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    contractsByOpstinaIDAndDatumU(
      opstinaID: $id
      datumU: $date
      limit: 100000
      nextToken: $nextToken
    ) {
      items {
        id
        datumU
        ppNaziv
        cenaEUR
        n
      }
      nextToken
    }
  }
`;

export const contractsBySubRegionAndDate = /* GraphQL */ `
  query ContractsByKoIDAndDatumU(
    $id: Int!
    $date: ModelStringKeyConditionInput
    $nextToken: String
  ) {
    contractsByKoIDAndDatumU(
      koID: $id
      datumU: $date
      limit: 100000
      nextToken: $nextToken
    ) {
      items {
        id
        datumU
        ppNaziv
        cenaEUR
        n
      }
      nextToken
    }
  }
`;
