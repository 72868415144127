import React, { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import { ThemeContext } from './contexts/ThemeContext';
import Header from './components/header/Header';
import HeaderSecondary from './components/header-secondary/HeaderSecondary';
import Footer from './components/footer/Footer';
import Report from './components/report/Report';
import About from './components/about/About';
import Donate from './components/donate/Donate';

import './App.scss';

export default function App() {
  const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

  const getDefaultTheme = () => {
    const localStorageTheme = localStorage.getItem('theme');
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    ReactGA4.event({
      category: 'Theme',
      action: localStorageTheme || browserDefault,
    });
    return localStorageTheme || browserDefault;
  };

  const [theme, setTheme] = useState(getDefaultTheme());
  document.documentElement.className = `theme-${theme}`;

  return (
    <BrowserRouter>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <div className='app-container'>
          <div className='content'>
            <Header />
            <HeaderSecondary />
            <Routes>
              <Route path='/' element={<Report />} />
              <Route path='about' element={<About />} />
              <Route path='donate' element={<Donate />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </ThemeContext.Provider>
    </BrowserRouter>
  );
}
