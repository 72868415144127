import React, { useEffect } from 'react';
import ReactGA4 from 'react-ga4';

import './Donate.scss';

export default function Donate() {
  useEffect(() => {
    ReactGA4.event({
      category: 'Page',
      action: 'Donate',
    });
  }, []);

  return (
    <div className='donate-container'>
      <div className='donate-text'>
        <div>
          Ukoliko si zadovoljan sadržajem i ako ti je platforma od pomoći, donacije su dobrodošle.
        </div>
        <div>Svaka donacija znači i doprinosi daljim unapređenjima.</div>
      </div>
      <div className='donate-money'>
        <div className='donate-button paypal'>
          <a target='_blank' rel='noreferrer' href='https://www.paypal.com/paypalme/wolfsrb'>
            Paypal
          </a>
        </div>
        <div className='donate-button cofee'>
          <a target='_blank' rel='noreferrer' href='https://www.buymeacoffee.com/wolfsrb'>
            Buy Me a Coffee
          </a>
        </div>
      </div>
      <div className='donate-money'>
        <div className='donate-crypto'>
          <div className='qr eth' />
          <div>
            <div>ETH adresa (ERC20):</div>
            <div className='eth-adress'>0xbfbc6db16c3fa02627f5517043e1101dfca9c890</div>
          </div>
        </div>
        <div className='donate-crypto'>
          <div className='qr btc' />
          <div>
            <div>BTC adresa:</div>
            <div className='eth-adress'>1PVL3AZkBWFtDXiAxZQTxKzdgsB8MuJWsu</div>
          </div>
        </div>
      </div>
    </div>
  );
}
